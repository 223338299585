<template>
  <div>
    <div class="table-container">
      <table v-if="TableType === 'Paid'">
        <thead>
          <tr>
            <th :style="{ backgroundColor: firstColumnColor }" style="color: #2D3250 !important;">
              {{ columns[0].title }}
            </th>
            <th style="color: #2D3250 !important;" v-for="(column, index) in columns.slice(1)" :key="index"
              :style="{ backgroundColor: column ? column.color : '' }">
              {{ columns[index + 1].title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex">
            <td @click="isTableItemClicked('clicked-table-item')" :style="getCellStyle(columns[0].color, row.date)"
              style="font-weight: bold; cursor: pointer;">
              {{ row.date }}
            </td>
            <td :style="getCellStyle(columns[1].color, row.costUnformatted)">
              {{ row.cost }}
            </td>
            <td :style="getCellStyle(columns[2].color, row.impressionsUnformatted)
              ">
              {{ row.impressions }}
            </td>
            <td :style="getCellStyle(columns[3].color, row.clicksUnformatted)">
              {{ row.clicks }}
            </td>
            <td :style="getCellStyle(columns[4].color, row.ctrUnformatted)">
              {{ row.ctr }}
            </td>
            <td :style="getCellStyle(columns[5].color, row.avg_cpcUnformatted)">
              {{ row.avg_cpc }}
            </td>
            <td :style="getCellStyle(columns[6].color, row.storeVisitsUnformatted)
              ">
              {{ row.storeVisits }}
            </td>
            <td :style="getCellStyle(columns[7].color, row.CPSVUnformattedUnformatted)
              ">
              {{ row.CPSV }}
            </td>
            <td :style="getCellStyle(columns[8].color, row.avg_cpmUnformatted)">
              {{ row.avg_cpm }}
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="TableType === 'StoreLevel'">
        <thead>
          <tr>
            <th :style="{ backgroundColor: firstColumnColor }">
              {{ columnsStoreLevel[0].title }}
            </th>
            <th v-for="(column, index) in columnsStoreLevel.slice(1)" :key="index"
              :style="{ backgroundColor: column ? column.color : '' }">
              {{ columnsStoreLevel[index + 1].title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex">
            <td :style="getCellStyle(columnsStoreLevel[0].color, row.storeName)"
              style="font-weight:500; text-transform: capitalize;">
              {{ row.storeName }}
            </td>
            <td :style="getCellStyle(columnsStoreLevel[2].color, row.cost)">
              {{ row.cost }}
            </td>
            <td :style="getCellStyle(columnsStoreLevel[1].color, row.impressions)">
              {{ row.impressions }}
            </td>
            <td :style="getCellStyle(columnsStoreLevel[3].color, row.storeVisits)">
              {{ row.storeVisits }}
            </td>
            <td :style="getCellStyle(columnsStoreLevel[4].color, row.drivingDirections)
              ">
              {{ row.drivingDirections }}
            </td>
            <td :style="getCellStyle(
              columnsStoreLevel[5].color,
              row.storeVisitsViewThrough
            )
              ">
              {{ row.storeVisitsViewThrough }}
            </td>
            <td :style="getCellStyle(columnsStoreLevel[6].color, row.costPerStoreVisit)
              ">
              {{ row.costPerStoreVisit }}
            </td>
            <td :style="getCellStyle(columnsStoreLevel[7].color, row.lrsvCVR)">
              {{ row.lrsvCVR }}
            </td>
          </tr>
        </tbody>
      </table>
      <table v-if="TableType === 'SummaryNewBrandTable'" class="summaryTableForDealer">
        <thead>
          <tr>
            <th :style="{ backgroundColor: firstColumnColor }" class="thforGMBDealers">
              {{ columnsSummaryNewLevel[0].title }}
            </th>
            <th v-for="(column, index) in columnsSummaryNewLevel.slice(1)" :key="index"
              :style="{ backgroundColor: column ? column.color : '' }" class="thforGMBDealers">
              {{ columnsSummaryNewLevel[index + 1].title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex">
            <td @click="isTableItemClicked" :style="getCellStyle(columnsSummaryNewLevel[0].color, row.name)"
              style=" text-transform: capitalize;">
              <span style="font-size: 13px;">
                {{ row.name }} <br />
                <span style="font-size: 10px;">
                  {{ row.campaignName }}
                </span>
              </span>
            </td>
            <td :style="getCellStyle(columnsSummaryNewLevel[2].color, row.impressions)
              ">
              {{ row.impressions }}
            </td>
            <td :style="getCellStyle(columnsSummaryNewLevel[1].color, row.clicks)">
              {{ row.clicks }}
            </td>
            <td :style="getCellStyle(columnsSummaryNewLevel[6].color, row.mediaSpent)
              ">
              {{ row.mediaSpent }}
            </td>
            <td :style="getCellStyle(
              columnsSummaryNewLevel[5].color,
              row.PaidStoreVisits
            )
              ">
              {{ row.PaidStoreVisits }}
            </td>
            <td :style="getCellStyle(
              columnsSummaryNewLevel[4].color,
              row.OrganicStoreVisits
            )
              ">
              {{ row.OrganicStoreVisits }}
            </td>
            <td :style="getCellStyle(
              columnsSummaryNewLevel[3].color,
              row.totalStoreVisits
            )
              ">
              {{ row.totalStoreVisits }}
            </td>

            <!-- <td
              :style="
                getCellStyle(
                  columnsSummaryNewLevel[4].color,
                  row.incrementalStoreVisits
                )
              "
            >
              {{ row.incrementalStoreVisits }}
            </td> -->
            <!-- <td
              :style="
                getCellStyle(
                  columnsSummaryNewLevel[3].color,
                  row.incrementalStoreVisitsDelta
                )
              "
            >
              {{ row.incrementalStoreVisitsDelta }}
            </td> -->

            <td :style="getCellStyle(
              columnsSummaryNewLevel[7].color,
              row.costPerStoreVisit
            )
              ">
              {{ row.costPerStoreVisit }}
            </td>
            <td :style="getCellStyle(columnsSummaryNewLevel[8].color, row.calls)">
              {{ row.calls }}
            </td>
            <!-- <td
              :style="
                getCellStyle(
                  columnsSummaryNewLevel[8].color,
                  row.costPerIncrementalStoreVisit
                )
              "
            >
              {{ row.costPerIncrementalStoreVisit }}
            </td> -->
            <!-- <td
              :style="
                getCellStyle(
                  columnsSummaryNewLevel[9].color,
                  row.costPerIncrementalStoreVisitDelta
                )
              "
            >
              {{ row.costPerIncrementalStoreVisitDelta }}
            </td> -->
            <!-- <td :style="getCellStyle(columnsSummaryNewLevel[9].color, row.mediaSpent)">
              {{ row.mediaSpent }}
            </td>  -->
          </tr>
          <!-- <tr
            style="font-weight: bold; color: black; background-color: #F1EFEF;"
          >
            <td>{{ displayTotalData.name }}</td>
            <td>{{ displayTotalData.totalStoreVisits }}</td>
            <td>{{ displayTotalData.organicStoreVisits }}</td>
            <td>{{ displayTotalData.paidStoreVisits }}</td>
            <td>{{ displayTotalData.incStoreVisits }}</td>
            <td>{{ displayTotalData.incStoreVisitsDelta }}</td>
            <td>{{ displayTotalData.mediaSpent }}</td>
            <td>{{ displayTotalData.costPerStoreVisit }}</td>
            <td>{{ displayTotalData.costPerIncStoreVisit }}</td>
            <td>{{ displayTotalData.costPerIncStoreVisitDelta }}</td>
          </tr> -->
        </tbody>
      </table>
      <table v-if="TableType === 'Dealer'" class="dealerTableForDealer">
        <thead>
          <tr>
            <th :style="{ backgroundColor: firstColumnColor }" class="thforGMBDealersIndividual">
              {{ columnsDealerSummaryNewLevel[0].title }}
            </th>
            <th v-for="(column, index) in columnsDealerSummaryNewLevel.slice(1)" class="thforGMBDealersIndividual"
              :key="index" :style="{ backgroundColor: column ? column.color : '' }">
              {{ columnsDealerSummaryNewLevel[index + 1].title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex">
            <td :style="getCellStyle(columnsDealerSummaryNewLevel[0].color, row.name)
              ">
              {{ row.name }}
            </td>
            <td :style="getCellStyle(
              columnsDealerSummaryNewLevel[1].color,
              row.ig_followers
            )
              ">
              {{ row.ig_followers }}
            </td>
            <td :style="getCellStyle(
              columnsDealerSummaryNewLevel[2].color,
              row.ig_reach
            )
              ">
              {{ row.ig_reach }}
            </td>
            <td :style="getCellStyle(
              columnsDealerSummaryNewLevel[3].color,
              row.ig_engagement
            )
              ">
              {{ row.ig_engagement }}
            </td>
            <td :style="getCellStyle(
              columnsDealerSummaryNewLevel[4].color,
              row.phoneCalls
            )
              ">
              {{ row.phoneCalls }}
            </td>
            <td :style="getCellStyle(
              columnsDealerSummaryNewLevel[5].color,
              row.paidStoreVisits
            )
              ">
              {{ row.paidStoreVisits }}
            </td>

            <td :style="getCellStyle(columnsDealerSummaryNewLevel[6].color, row.cost)
              ">
              {{ row.cost }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="TableType === 'keywordPlanner'" class="keywordTableContainer">
        <div v-if="isKeywordDataFetched"  class="spinnerCentered">
          <multiChannelSpinner />
        </div>
        <div v-else>
          <table class="dealerTableForDealer">
            <thead>
              <tr>
                <th style="border: 0.5px solid #e7e7e7;" :style="{ backgroundColor: firstColumnColor }"
                  class="thforGMBDealersIndividual">
                  {{ keyWordPlanner[0].title }}
                </th>
                <th style="border: 0.5px solid #e7e7e7;" v-for="(column, index) in keyWordPlanner.slice(1)"
                  class="thforGMBDealersIndividual" :key="index" :style="{ backgroundColor: column ? column.color : '' }">
                  {{ keyWordPlanner[index + 1].title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, rowIndex) in displayedData">
                <tr :key="rowIndex" :class="{ opened: opened.includes(rowIndex) }">
                  <td :style="getCellStyle(keyWordPlanner[0].color, row.keywordText)" style="width: 20%;" @click="toggle(rowIndex, row.data)">
                    <div style="display: flex; align-items: left; text-align: left;">
                      <b-button variant="link">
                      <span style="color: rgb(126, 126, 126) !important; font-size: 12px; float: left; white-space: nowrap;">
                        <feather-icon :icon="opened.includes(rowIndex) ? 'MinusCircleIcon' : 'PlusCircleIcon'" size="12" color="#200E6B" class="mr-1" />
                        <span>{{ row.keyword }}</span>
                      </span>
                    </b-button>
                    </div>
                  </td>
                  <!-- <td :style="getCellStyle(keyWordPlanner[11].color, row.cumulative.searchTerm)">
                  {{ row.cumulative.searchTerm }}
                </td>
                <td :style="getCellStyle(keyWordPlanner[12].color, row.cumulative.haloSales)">
                  {{ row.cumulative.matchType }}
                </td> -->
                  <td :style="getCellStyle(
                    keyWordPlanner[1].color,
                    row.cumulative.impressions
                  )
                    ">
                    {{ row.cumulative.impressions }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[2].color, row.cumulative.clicks)
                    ">
                    {{ row.cumulative.clicks }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[3].color, row.cumulative.ctr)
                    ">
                    {{ row.cumulative.ctr }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[4].color, row.cumulative.spends)
                    ">
                    {{ row.cumulative.spends }}
                  </td>
                  <td :style="getCellStyle(
                    keyWordPlanner[5].color,
                    row.cumulative.costPerClick
                  )
                    ">
                    {{ row.cumulative.costPerClick }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[6].color, row.cumulative.sales)
                    ">
                    {{ row.cumulative.sales }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[7].color, row.cumulative.orders)
                    ">
                    {{ row.cumulative.orders }}
                  </td>
                  <td :style="getCellStyle(
                    keyWordPlanner[8].color,
                    row.cumulative.conversionRate
                  )
                    ">
                    {{ row.cumulative.conversionRate }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[9].color, row.cumulative.acos)
                    ">
                    {{ row.cumulative.acos }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[10].color, row.cumulative.roas)
                    ">
                    {{ row.cumulative.roas }}
                  </td>
                  <td :style="getCellStyle(keyWordPlanner[11].color, row.cumulative.aov)
                    ">
                    {{ row.cumulative.aov }}
                  </td>
                </tr>
                <tr class="animate" v-if="opened.includes(rowIndex)">
                  <td colspan="14">
                    <div style="margin:5px; float: right;">
                <b-button   class="exportButton"  size="sm" variant="primary" @click="exportData(displayedInsideData)"
                ><span style="font-weight: bold;">
                  Export Data
                  <feather-icon
                    icon="DownloadIcon"
                    size="15"
                    style="margin-left: 5px;"/></span
              ></b-button>
              </div>
                    <table>
                      <thead>
                        <tr>
                          <th v-for="(column, index) in keyWordPlannerInsideData" class="thforGMBDealersIndividual"
                            :key="index" :style="{ backgroundColor: column ? column.color : '' }">
                            {{ keyWordPlannerInsideData[index].title }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, rowIndex) in displayedInsideData" :key="rowIndex">
                          <td :style="getCellStyle(
                            keyWordPlannerInsideData[1].color,
                            row.searchTerm
                          )
                            ">
                            {{ row.searchTerm }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[2].color, row.matchType)
                            ">
                            {{ row.matchType }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[3].color, row.keywordType)
                            ">
                            {{ row.keywordType }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[4].color, row.impressions)
                            ">
                            {{ row.impressions }}
                          </td>
                          <td :style="getCellStyle(
                            keyWordPlannerInsideData[5].color,
                            row.clicks
                          )
                            ">
                            {{ row.clicks }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[6].color, row.ctr)
                            ">
                            {{ row.ctr }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[7].color, row.spends)
                            ">
                            {{ row.spends }}
                          </td>
                          <td :style="getCellStyle(
                            keyWordPlannerInsideData[8].color,
                            row.costPerClick
                          )
                            ">
                            {{ row.costPerClick }}
                          </td>
                          <td :style="getCellStyle(
                            keyWordPlannerInsideData[8].color,
                            row.sales
                          )
                            ">
                            {{ row.sales }}
                          </td>
                          <td :style="getCellStyle(
                            keyWordPlannerInsideData[8].color,
                            row.orders
                          )
                            ">
                            {{ row.orders }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[9].color, row.conversionRate)
                            ">
                            {{ row.conversionRate }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[9].color, row.acos)
                            ">
                            {{ row.acos }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[10].color, row.roas)
                            ">
                            {{ row.roas }}
                          </td>
                          <td :style="getCellStyle(keyWordPlannerInsideData[11].color, row.aov)
                            ">
                            {{ row.aov }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div style="justify-content: center; margin-top: 10px; height: 1;">
                  <b-pagination v-model="currentTablePageInside" :total-rows="totalPaidRowsInside"
                    :per-page="totalPaidInside" first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                    class="mt-1 mb-0"></b-pagination>
                </div>
                  </td>
                </tr>
              </template>
              <tr style="font-size: 12px; font-weight: bold; background-color: #EDEDED;">
                <td>{{ this.PaidData[0].key }}</td>
                <td>{{ this.PaidData[0].impressions }}</td>
                <td>{{ this.PaidData[0].clicks }}</td>
                <td>{{ this.PaidData[0].ctr }}</td>
                <td>{{ this.PaidData[0].spends }}</td>
                <td>{{ this.PaidData[0].costPerClick }}</td>
                <td>{{ this.PaidData[0].sales }}</td>
                <td>{{ this.PaidData[0].orders }}</td>
                <td>{{ this.PaidData[0].conversionRate }}</td>
                <td>{{ this.PaidData[0].acos }}</td>
                <td>{{ this.PaidData[0].roas }}</td>
                <td>{{ this.PaidData[0].aov }}</td>
              </tr>
            </tbody>
          </table>
        </div>
    
      </div> 
      <div v-if="this.PaidData.length === 0">
        <div
          style="text-align: center; justify-content: center; align-items:  center; margin-left: 0%; right:auto ; margin-top:10% ; height: 20vh;">
          <p class="text-primary">No Data Found For Such Dealer(s).</p>
        </div>
      </div>
    </div>
    <div style="justify-content: center; margin-top: 10px; height: 1;">
      <b-pagination v-model="currentTablePage" v-if="this.PaidData.length > 0" :total-rows="totalPaidRows"
        :per-page="totalPaid" first-number last-number align="right" prev-class="prev-item" next-class="next-item"
        class="mt-1 mb-0"></b-pagination>
    </div>
  </div>
</template>

<script>
import { BPagination, BButton } from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
export default {
  components: { BPagination, BButton, multiChannelSpinner},
  data() {
    return {
      searchTerm: "",
      columns: [
        { title: "Day", color: "#8B865F" },
        { title: "Cost", color: "#8B865F" },
        { title: "Impressions", color: "#758DAC" },
        { title: "Clicks", color: "#B0CFD2" },
        { title: "CTR %", color: "#FDC9AC" },
        { title: "Avg. CPC", color: "#FFA800" },
        { title: "Store Visits", color: "#C45480" },
        { title: "CPSV", color: "#CEEAFD" },
        { title: "Avg. CPM", color: "#FCBDCF" }
      ],
      columnsStoreLevel: [
        { title: "Store Local Business Name", color: "#8B865F" },
        { title: "Cost", color: "#335e96" },
        { title: "Local Reach(Impressions)", color: "#a09950" },
        { title: "Store Visits", color: "#0b848e" },
        { title: "Driving Directions", color: "#ffe9de" },
        { title: "Store Visits (View Through)", color: "#fef5e4" },
        { title: "CPSV", color: "#d7e8c8" },
        { title: "LR-SV CVR", color: "#d9d6d7" }
      ],
      columnsSummaryNewLevel: [
        { title: "Dealer Name", color: "#8B865F" },
        { title: "Impressions", color: "#ffe9de" },
        { title: "Clicks", color: "#d8e9c9" },
        { title: "Spends", color: "#d7e8c8" },
        { title: "Paid SV", color: "#FFC0D9" },
        { title: "Organic SV", color: "#a09950" },
        { title: "Total SV", color: "#335e96" },
        { title: "CPSV", color: "#d9d6d7" },
        { title: "Calls Done", color: "#FCBDCF" }
      ],
      columnsDealerSummaryNewLevel: [
        { title: "Month", color: "#8B865F" },
        { title: "Instagram Followers", color: "#335e96" },
        { title: "Instagram Reach", color: "#a09950" },
        { title: "Instagram Engagement", color: "#0b848e" },
        { title: "Phone Calls Done", color: "#F9EFDB" },
        { title: "Store Visits", color: "#F8F4EC" },
        { title: "Cost", color: "#E6BAA3" }
      ],
      keyWordPlanner: [
        { title: "Keyword Text", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "Sales", color: "#ffffff" },
        { title: "Orders", color: "#ffffff" },
        { title: "CR", color: "#ffffff" },
        { title: "ACOS", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
        { title: "AOV", color: "#ffffff" }
      ],
      keyWordPlannerInsideData: [
        { title: "Search Term", color: "#ffffff" },
        { title: "Match Type", color: "#ffffff" },
        { title: "Keyword Type", color: "#ffffff" },
        { title: "Impressions", color: "#ffffff" },
        { title: "Clicks", color: "#ffffff" },
        { title: "CTR %", color: "#ffffff" },
        { title: "Spends", color: "#ffffff" },
        { title: "CPC", color: "#ffffff" },
        { title: "Sales", color: "#ffffff" },
        { title: "Orders", color: "#ffffff" },
        { title: "CR", color: "#ffffff" },
        { title: "ACOS", color: "#ffffff" },
        { title: "ROAS", color: "#ffffff" },
        { title: "AOV", color: "#ffffff" }
      ],
      currentTablePage: 1,
      firstColumnColor: "#000000",
      totalPaid: 8,
      opened: [],
      toggleFlag: false,
      keyWordInsideData: [],
      currentTablePageInside:1,
      totalPaidInside:10,
    };
  },
  props: {
    PaidData: {
      type: Array
    },
    SummaryTotalData: {
      type: Object
    },
    SearchData: {
      type: String
    },
    TableType: {
      type: String
    },
    isKeywordDataFetched:{
      type: Boolean
    }
  },
  computed: {
    calculateTotal() {
      return metric => {
        return this.PaidData.reduce((total, item) => total + item[metric], 0);
      };
    },
    totalPaidRows() {
      if(this.TableType === "keywordPlanner"){
       return  Object.keys(this.PaidData[1]).length
      }
      return this.PaidData.length;
    },
    totalPaidRowsInside() {
      return this.keyWordInsideData.length;
    },
    displayTotalData() {
      return this.SummaryTotalData;
    },
    displayedData() {
      if (this.TableType === "keywordPlanner") {
        let filterDataArray = Object.values(this.PaidData[1])
        const filteredData = filterDataArray.filter(
          item =>
            item &&
            item.keyword &&
            item.keyword.toLowerCase().includes(this.SearchData.toLowerCase())
        );

        const startIndex = (this.currentTablePage - 1) * this.totalPaid;
        const endIndex = startIndex + this.totalPaid;

        return filteredData.slice(startIndex, endIndex);
      }
      const startIndex = (this.currentTablePage - 1) * this.totalPaid;
      const endIndex = startIndex + this.totalPaid;
      return this.PaidData.slice(startIndex, endIndex);
    },
    displayedInsideData() {
      const startIndex = (this.currentTablePageInside - 1) * this.totalPaidInside;
      const endIndex = startIndex + this.totalPaidInside;
      return this.keyWordInsideData.slice(startIndex, endIndex);
    }
  },
  methods: {
    toggle(rowIndex, data) {
        if (this.opened.length > 0) {
        const previousIndex = this.opened[0];
        this.opened = [];
        if (previousIndex === rowIndex) {
          return; 
        }
      }
      this.keyWordInsideData = [...data];
      this.opened.push(rowIndex); 
    },
    isTableItemClicked() {
      this.$emit("isTableItemClicked", true);
    },
    handlePageInputChange() {
      if (this.goToPage < 1) {
        this.goToPage = 1;
      } else if (this.goToPage > this.totalPages) {
        this.goToPage = this.totalPages;
      }
    },
    goToRequestedPage() {
      const requestedPage = parseInt(this.goToPage) - 1;
      if (requestedPage >= 0 && requestedPage < this.totalPages) {
        this.currentPage = requestedPage;
      }
    },
    sortByFirstColumn() {
      if (this.firstColumnSortOrder === "asc") {
        // Sort in ascending order
        this.sortedData = [...this.displayedData].sort((a, b) =>
          a[0].localeCompare(b[0])
        );
        this.firstColumnSortOrder = "desc";
      } else {
        // Sort in descending order
        this.sortedData = [...this.displayedData].sort((a, b) =>
          b[0].localeCompare(a[0])
        );
        this.firstColumnSortOrder = "asc";
      }
    },
    getCellStyle(columnColor, value) {
      if (value === 0) {
        return `background-color: white; text-align: center; color: #000000;`;
      }
      const ranges = [
        { min: 1, max: 999999 },
        { min: 100000, max: 99999999 }
      ];
      let opacity = 0;
      let rgbColor = "";
      for (const range of ranges) {
        if (value >= range.min && value <= range.max) {
          const rangeSize = range.max - range.min;
          opacity = (value - range.min) / rangeSize;
          opacity = opacity * 0.5 + 0.5;
          opacity = opacity.toFixed(2);
          rgbColor = this.hexToRgb(columnColor);
          break;
        }
      }
      return `background-color: rgba(${rgbColor}, ${opacity}); text-align: center; color: #555555;`;
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, "");
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    exportData(data) {
      // console.log(data);
      // Helper function to escape and quote each value
      const escapeCSV = str => {
        if (str === null || str === undefined) {
          // Handle null and undefined
          return '""';
        }
        // Convert to string, escape double quotes by doubling them, and wrap in double quotes
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Generate CSV header
      const header = Object.keys(data[0])
        .map(field => escapeCSV(field))
        .join(",");

      // Generate CSV rows
      const rows = data.map(item =>
        Object.values(item)
          .map(value => escapeCSV(value))
          .join(",")
      );

      // Combine header and rows, separated by newline
      const csv = [header].concat(rows).join("\n");

      // Create a Blob for the CSV
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a temporary download link and trigger the download
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `Keyword_Level_Performance.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
      }
    }
  }
};
</script>

<style scoped>
.sort-icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-width: 4px;
  border-style: solid;
  border-color: transparent transparent #000;
}

.asc {
  border-width: 4px 4px 0 4px;
  border-color: #000 transparent transparent transparent;
}

.desc {
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #000 transparent;
}

/* Add your custom CSS styling here */

.table-container {
  width: 100%;
  /* overflow-x: auto; */
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgb(215, 215, 215);
}

th,
td {
  padding: 8px;
  text-align: center;
  border: 0.5px solid #e7e7e7;
}
td:hover{
  background-color: rgb(250, 250, 250);
  cursor: pointer;
}
th {
  text-align: center;
  font-weight: bold;
  border: none;
  color: #ef8b6c;
  font-weight: bold;
  background-color: #ffffff !important;
}

.summaryTableForDealer td {
  color: rgb(126, 126, 126) !important;
  font-size: 12px !important;
  padding: 5px;
}
.keywordTableContainer{
  overflow-x: auto; 
  -webkit-overflow-scrolling: touch; 
  padding: 5px;
}
.dealerTableForDealer td {
  color: rgb(126, 126, 126) !important;
  font-size: 12px !important;
}
.dealerTableForDealer td:hover {
  background-color: rgb(250, 250, 250);
}

.thforGMBDealers,
.thforGMBDealersIndividual {
  text-align: center;
  font-weight: bold;
  border: none;
  color: #7b7a7a !important;
  font-weight: bold;
  background-color: #ffffff !important;
}

.pagination {
  margin-top: 10px;
  text-align: center;
  float: right;
}

.keyword-cell {
  display: flex;
  align-items: center;
}

.pagination-button {
  background-color: white;
  color: #fff;
  border: 1px solid #0d1282;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
}

.pagination-button:disabled {
  background-color: #fcf9f9;
  cursor: not-allowed;
  opacity: 0.3;
}

.pagination-label {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.page-input {
  width: 40px;
  text-align: center;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.go-button {
  background-color: #0d1282;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 5px;
}

.opened {
  background-color: #e5f1f8;
  border: none;
}

.opened td {
  opacity: 1;
  transition: opacity 0.5s;
  /* You can adjust the transition properties */
}

.closed td {
  opacity: 0;
  transition: opacity 0.8s;
  /* You can adjust the transition properties */
  display: none;
}

.animate {
  animation-duration: 1s; /* Increase the duration to 1s for a smoother effect */
  animation-name: animate-fade-move;
  animation-fill-mode: forwards; /* Change to 'forwards' to keep the final state */
}

@keyframes animate-fade-move {
  0% {
    opacity: 0;
    transform: translateX(50px); /* Adjust the value based on your needs */
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  /* Set the height of the container */
}
</style>
